import React, { Component } from "react"
import {
  Badge,
  Button,
  Col,
  Row,
} from 'antd'
import { Link } from "gatsby"
import SEO from '../components/seo'
// import Topbar from '../components/topbar'
import { navigate } from '@reach/router';
import { HeartFilled, GlobalOutlined, NotificationFilled, CodeFilled, FieldTimeOutlined } from '@ant-design/icons'
import Footer from '../components/footer'
import Topbar from '../components/topbar'
import IntegratedWith from '../components/integratedWith'

class IndexPage extends Component {

  constructor(props) {
    super(props);

    this.goTo = this.goTo.bind(this);
  }

  componentDidMount() {

    if (window && window.CM) {
      if (window.cmAgent) {
        window.cmAgent.pageview();
        window.cmAgent.dispatch()
      }
    }

    if (window && window.gtag) {
      window.gtag('config', 'G-KTYYBQVLCK', {'page_path': '/'});
    }
  }
  goTo(path) {
    const basePath = '/'
    navigate(basePath+path)
  }

  render() {

    // const notifications = [
    //   {title: 'Reset password', active: true, canMute: false, widget: true, widgetEdit: true, email: true, emailEdit: false, sms: true, smsEdit: true},
    //   {title: 'Billing problem', active: true, canMute: false, widget: true, widgetEdit: true, email: true, emailEdit: false, sms: true, smsEdit: true},
    //   {title: 'Notification A', active: true, canMute: true, widget: true, widgetEdit: true, email: true, emailEdit: true, sms: false, smsEdit: true},
    //   {title: 'Notification B', active: true, canMute: true, widget: true, widgetEdit: true, email: true, emailEdit: true, sms: false, smsEdit: true},
    //   {title: 'Notification C', active: false, canMute: true, widget: true, widgetEdit: true, email: true, emailEdit: true, sms: false, smsEdit: true},
    //   {title: 'Notification D', active: true, canMute: true, widget: true, widgetEdit: true, email: true, emailEdit: true, sms: false, smsEdit: true},
    // ]

    return <React.Fragment>
      <SEO 
        title="Integrate a Notification Center in your SaaS app – today!"
        description="Notify your users right into your SaaS app, and any other channel."
        keywords={['notification', 'center', 'app', 'saas']}
      />
      <Topbar />

      <div className="container">

        <section className="hero">
          <h1>Integrate a Notification Center in your SaaS app – today!</h1>
          
          <h2>Notify your users right into your SaaS console, and any other channel.</h2>

          <div className="hero-cta">
            <Link to="/pricing" title="Get started" className="margin-r-l"><Button size="large" type="primary">Get Started</Button></Link>
            <a href="mailto:hello@notifuse.com" title="Book a demo"><Button size="large" type="primary" ghost>Book a demo</Button></a>
          </div>

          <Row gutter={24}>
            <Col sm={24} xl={12} order={1} style={{textAlign: 'center'}}>
              <img src="/images/widget-mock.png" alt="widget" style={{maxWidth: '100%'}} />
            </Col>

            <Col sm={24} xl={12} order={2}>
              <Row gutter={24}>
                <Col sm={24} md={6} xl={24}>
                  <h3>Plug & play widget</h3>
                  <div className="subtitle">Integrate a notification bell "a la Facebook" right into your SaaS.</div>
                </Col>
                <Col sm={24} md={6} xl={24}>
                  <h3>Cross-channel notifications</h3>
                  <div className="subtitle">Email, SMS, in-app, Slack, webhook...</div>
                </Col>
                <Col sm={24} md={6} xl={24}>
                  <h3>Templating engine</h3>
                  <div className="subtitle">Don't bother your developers anymore, you can update your notifications templates online.</div>
                </Col>
                <Col sm={24} md={6} xl={24}>
                  <h3>Integrations & API</h3>
                  <div className="subtitle">Connect your notifications channels in a click & start sending notifications with one line of code & the API.</div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="hero-left">
          </div>
        </section>
      </div>

      <Row>
        <Col sm={24} md={12} className="for-market">
          <div className="half-container">
            <h1>For Customer Success</h1>
            <p><strong>Boost engagement & retention</strong> by delivering important transactionnal notifications to your users wherever they are.</p>
            <p><strong>Don't bother your developers anymore</strong>, you can update the notifications templates & translations directly from Notifuse.</p>
          </div>
        </Col>

        <Col sm={24} md={12} className="for-dev">
          <div className="half-container">
            <h1>For Developers</h1>
            <p><strong>Don't waste your time reinventing the wheel</strong> while you can <strong>integrate a state-of-the-art Notification Center today!</strong></p>
            <p>Having troubles with the <a href="https://docs.notifuse.com" title="Documentation">API & documentation</a>? <strong>We are here to help!</strong></p>
          </div>
        </Col>
      </Row>

      <div className="container">
        <section className="block-settings">
          <h1>Give control back to your users</h1>
          <h2>Let them choose how they want to receive your notifications</h2>
          <div style={{textAlign: 'center'}}>
            <img src="/images/widget-settings-mock.png" alt="widget settings" style={{maxWidth: '100%'}} />
          </div>
        </section>

        <div className="divider"></div>

        <section className="features">
          <h1>Incredible features</h1>

          <Row>
            <Col sm={12} md={8} className="feature">
              <div className="feature-title"><HeartFilled className="icon" /> Best in class UX</div>
              <div className="feature-desc">Drive engagement & retention with an easy-to-use & customizable widget.</div>
            </Col>
            <Col sm={12} md={8} className="feature">
              <div className="feature-title"><GlobalOutlined className="icon" /> Internationalization</div>
              <div className="feature-desc">Translate your templates in any language.</div>
            </Col>
            <Col sm={12} md={8} className="feature">
              <div className="feature-title"><NotificationFilled className="icon" /> Broadcasting</div>
              <div className="feature-desc">Send general announcements to all your users with a click. <span className="soon">Coming soon</span></div>
            </Col>

            <Col sm={12} md={8} className="feature">
              <div className="feature-title"><span className="icon"><Badge count={900} style={{ backgroundColor: 'black' }} /></span> Stacking</div>
              <div className="feature-desc">Increment a counter instead of spamming similar notifiations.</div>
            </Col>
            <Col sm={12} md={8} className="feature">
              <div className="feature-title"><CodeFilled className="icon" /> Powerful templating engine</div>
              <div className="feature-desc">Use layouts and templating markup (twig/jinja2 syntax) to customize your templates.</div>
            </Col>
            <Col sm={12} md={8} className="feature">
              <div className="feature-title"><FieldTimeOutlined className="icon" /> Scheduled notifications</div>
              <div className="feature-desc">Optimize your opening rate by sending your notifications at a specific time. <span className="soon">Coming soon</span></div>
            </Col>
          </Row>
        </section>

        <IntegratedWith />
      </div>

      <Footer />
    </React.Fragment>
  }
}

export default IndexPage
